body {
  font-family: Arial, Helvetica, sans-serif;
  background-image: url('specialdelivery.jpg');
  background-position-x: left;
  background-position-y: center;
}

.nav {
  display: flex;
  align-items: center;
  background-color: teal;
  padding: 30px 25px;
}

.lunaImg {
  width: 80px;
  margin-right: 10px;
}

.main {
  margin-top: 30px;
  background-color: lightgray;
  width: 700px;
  padding-bottom: 4px;
}

.facts {
  padding-top: 20px;
  margin-left: 30px;
}
.main > ul {
  margin-top: 30px;
  line-height: 20px;
}

.main > ul > li {
  padding-block: 8px;
}

.main > ul > li::marker {
  font-size: 1.4rem;
  color: teal;
}

.menu {
  display: flex;
  list-style: none;
  margin-left: auto;
  font-size: 18px;
}

.menu > li {
  padding: 8px;
}

.footer {
  font-style: italic;
  color: white;
  margin-top: 20px;
  margin-left: 4px;
}

section {
  padding-left: 20px;
}

.hero {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 80px;
  margin-top: 30px;
  text-align: center;
}

.hero--tophead{
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
}

.hero--bottomhead{
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 4rem;
}

.hero--text {
  margin-top: 0px;
  word-wrap: break-word;
  font-size: 1.1rem;
  line-height: 1.5;
  max-width: 900px;
  letter-spacing: 2px;
}